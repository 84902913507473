body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */

@media (max-width: 600px) {
  .MuiTypography-root {
    font-size: 14px;
  }

  .MuiButton-root {
    font-size: 12px;
  }

  .MuiGrid-root {
    flex-direction: column;
  }
}

/* In your CSS or styled-components file */
@media (max-width: 600px) {
  .MuiToolbar-root {
    flex-direction: column;
    align-items: flex-start;
  }

  .MuiButtonBase-root {
    margin: 5px 0;
  }
}